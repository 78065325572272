import { checkRut, prettifyRut } from 'react-rut-formatter';
import { validateRfc } from './forms';

export const VALIDATORS = {
  Chile: (nationalIdentifier) => checkRut(nationalIdentifier),
  Mexico: (nationalIdentifier) => validateRfc(nationalIdentifier),
};

export const SETTERS = {
  Chile: (nationalIdentifier) => prettifyRut(nationalIdentifier),
  Mexico: (nationalIdentifier) => nationalIdentifier,
};
